import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CoreModule } from "../../core/core.module";
import { ServiceModule } from "../../services/services.module";
import { SharedModule } from "../../shared/shared.module";
import { ProductsEditDialogComponent } from "./products-edit-dialog/products-edit-dialog.component";
import { ProductsOverviewComponent } from "./products-overview/products-overview.component";

@NgModule({
  declarations: [
    ProductsOverviewComponent,
    ProductsEditDialogComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    ServiceModule,
    MatProgressSpinnerModule,
  ]
})
export class ProductsModule { }
