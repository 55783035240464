import { HttpHeaders } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ApolloClientOptions, InMemoryCache, NormalizedCacheObject } from "@apollo/client/core";
import { APOLLO_OPTIONS, ApolloModule } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { AuthService } from "../services/auth/auth.service";
import { AppConfig } from "../services/config/models/app-config";

@NgModule({
  exports: [ ApolloModule ],
  providers: [
    {
        provide: APOLLO_OPTIONS,
        useFactory: (httpLink: HttpLink, appConfig: AppConfig, authService: AuthService) => ({
            link: httpLink.create({
                headers: new HttpHeaders({
                    "Authorization": authService.getAuthorizationHeaderValue() ?? "",
                }),
                uri: `${appConfig.Api.BaseUrl}/graphql`,
            }),
            cache: new InMemoryCache(),
        } as ApolloClientOptions<NormalizedCacheObject>),
        deps: [ HttpLink, AppConfig, AuthService ],
    },
  ],
})
export class GraphQLModule {}
