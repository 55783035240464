import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { registerLicense } from '@syncfusion/ej2-base';
import { AppModule } from './app/app.module';
import { AppConfig } from './app/services/config/models/app-config';
import * as Sentry from "@sentry/angular-ivy";

fetch('/assets/config/appsettings.json', {
    cache: 'no-cache'
})
    .then(response => response.json())
    .then((appConfig: AppConfig) => {
        if (appConfig.Production) {
            enableProdMode();
            initializeSentry(appConfig.Analytics.SentryDsn);
        }

        registerLicense('ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Qd0JiXH5XdXFSTmJd');

        platformBrowserDynamic([
            { provide: AppConfig, useValue: appConfig }
        ]).bootstrapModule(AppModule).then(ref => {
            // Ensure Angular destroys itself on hot reloads.
            if (window['ngRef']) {
                window['ngRef'].destroy();
            }
            window['ngRef'] = ref;

            // Otherwise, log the boot error
        }).catch(err => console.error(err));
    })
    .catch ((error: any) => {
        console.error(`Could not retrieve appConfig: ${error}`);
    });

function initializeSentry(dsn: string) {
    Sentry.init({
        dsn: dsn,
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: [ "localhost" ],
                routingInstrumentation: Sentry.routingInstrumentation
            })
        ],
        tracesSampleRate: 1.0
    })
}
