<mat-toolbar>
    <mat-toolbar-row>
        <button mat-icon-button *ngIf="showNavbarToggle" (click)="toggleSideNav.emit()"><mat-icon>menu</mat-icon></button>

        <span *ngIf="showNavbarToggle" class="toolbar-filler"></span>

        <a class="company-details" routerLink="">
            <img class="logo" alt="" src="{{ image }}" />
            <h4>{{ tenantName }}</h4>
        </a>

        <span class="toolbar-filler"></span>
    </mat-toolbar-row>
</mat-toolbar>
