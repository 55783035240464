import { Component, Inject, OnInit } from "@angular/core";
import { AuthService, AUTH_SERVICE } from "../auth.service";

@Component({
    selector: "app-auth-silent-callback",
    template: ""
})
export class AuthSilentCallbackComponent implements OnInit {

    public constructor(
        @Inject(AUTH_SERVICE) private authService: AuthService
    ) { }

    public ngOnInit(): void {
        this.authService.completeSilentAuthentication();
    }
}
