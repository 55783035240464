import { IGenericTableToolbarItem } from "./generic-table-toolbar-item";

export class GenericTableToolbarSearchItem implements IGenericTableToolbarItem {
    id: string | null;
    title: string | null;
    assign: "Left" | "Center" | "Right" | null;
    icon: string = "DEFAULT";
    disabled: boolean | null;
    filedType: "Button" | "Separator" | "Input" | null;
    action: () => void = () => { /* test */ };
}
