<div *ngIf="this.isLoading" class="center-spinner">
    <mat-spinner></mat-spinner>
</div>

<div class="full-page-table-container">
    <div class="button-row">
        <button mat-flat-button color="primary" (click)="import()">Importeren</button>
        <button mat-flat-button color="primary" (click)="exportVendit()">Exporteer Vendit</button>
    </div>
    <div class="table">
        <app-generic-table
            #suppliersproductsTable

            [toolbar]="tableToolbar"
            [headers]="tableHeaders"
            [dataSource]="supplierproducts"

            (recordDoubleClickEvent)="openDialog($event)"
        ></app-generic-table>
    </div>
</div>
