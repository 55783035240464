import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AUTH_SERVICE, AuthService } from "../../auth/auth.service";
import { PIM_API_BASE_URL } from "../api/api.service";
import { IDENTITY_API_BASE_URL } from "../identity/identity.service";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    public constructor(
        @Inject(AUTH_SERVICE) private authService: AuthService,
        @Inject(IDENTITY_API_BASE_URL) private identityApiBaseUrl: string,
        @Inject(PIM_API_BASE_URL) private pimApiBaseUrl: string,
    ) {}

    public intercept(request: HttpRequest<void>, next: HttpHandler): Observable<HttpEvent<void>> {
        // Only add authorization header to requests to the identity server and the api
        if (!request.url.includes(this.identityApiBaseUrl) && !request.url.includes(this.pimApiBaseUrl)) {
            return next.handle(request);
        }

        let sendingRequest: HttpRequest<void> = request;
        const authHeaderValue: string | null = this.authService.getAuthorizationHeaderValue();

        if (authHeaderValue) {
            sendingRequest = request.clone({
                setHeaders: {
                    Authorization: authHeaderValue
                }
            });
        }

        return next.handle(sendingRequest)
            .pipe(catchError((errResponse: HttpErrorResponse): Observable<never> => {
                if (errResponse.status === 401) {
                    this.authService.logout();
                    return of();
                }

                return throwError(() => errResponse);
            }));
    }
}
