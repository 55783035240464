<ejs-menu [items]="menuItems" orientation="Vertical" (select)="onMenuItemSelect($event)">
    <ng-template #template let-menuItems="">
        <div class="menuItemContainer">
            <mat-icon *ngIf="menuItems.iconCss != null">{{menuItems.iconCss}}</mat-icon>
            <div>
                {{menuItems.text}}
            </div>
        </div>
    </ng-template>
</ejs-menu>
<button mat-button class="logout" color="warn" (click)="logout()">Uitloggen</button>