import { AfterViewChecked, AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import { CommandClickEventArgs, CommandModel, FilterSettingsModel, GridComponent, RecordClickEventArgs, RecordDoubleClickEventArgs, RowDataBoundEventArgs, ToolbarItems } from "@syncfusion/ej2-angular-grids";
import { ItemModel } from "@syncfusion/ej2-angular-navigations";
import { L10n, setCulture, setCurrencyCode } from "@syncfusion/ej2-base";
import { Subject, takeUntil } from "rxjs";
import { IGenericTableHeader } from "./generic-table-header";
import { IGenericTableHeaderCommand } from "./generic-table-header-command";
import { GenericTableOptions } from "./generic-table-options";
import { GenericTableRowBindingEvent } from "./generic-table-row-binding-event";
import { IGenericTableToolbarItem } from "./generic-table-toolbar-item";
import { GenericTableToolbarSearchItem } from "./generic-table-toolbar-item-search";
import { IGenericTableOptions } from "./i-generic-table-options";
import { GenericTableToolbarColumnChooserItem } from "./generic-table-toolbar-item-column-chooser";

setCulture("nl-NL");
setCurrencyCode("EUR");

// https://github.com/syncfusion/ej2-locale
L10n.load({
    "nl-NL": {
        "grid": {
            EmptyRecord: "Geen resultaten",
            True: "Ja",
            False: "Nee",
            InvalidFilterMessage: "Verkeerde filter",
            "GroupDropArea": "Sleep een kolomkop hierheen om de kolom te groeperen",
            "UnGroup": "Klik hier om te degroeperen",
            "GroupDisable": "Groeperen is uitgeschakeld voor deze kolom",
            "FilterbarTitle": "\"s filterbalkcel",
            "EmptyDataSourceError": "DataSource mag niet leeg zijn bij de eerste laadactie, omdat kolommen worden gegenereerd vanuit dataSource in AutoGenerate Column Grid",
            "Add": "Toevoegen",
            "Edit": "Bewerk",
            "Cancel": "Annuleren",
            "Update": "Bijwerken",
            "Delete": "Verwijder",
            "Print": "Afdrukken",
            "Pdfexport": "PDF exporteren",
            "Excelexport": "Excel exporteren",
            "Wordexport": "Word exporteren",
            "Csvexport": "CSV-export",
            "Search": "Zoeken",
            "Columnchooser": "Kolommen",
            "Save": "Opslaan",
            "Item": "resultaat",
            "Items": "resultaten",
            EditOperationAlert: "Geen rijen geselecteerd om te bewerken",
            DeleteOperationAlert: "Geen rijen geselecteerd om te verwijderen",
            "SaveButton": "Opslaan",
            "OKButton": "OK",
            "CancelButton": "Annuleren",
            "EditFormTitle": "Bewerken van",
            AddFormTitle: "Voeg een nieuwe rij toe",
            BatchSaveConfirm: "Weet je zeker dat je het wilt opslaan?",
            "BatchSaveLostChanges": "Niet-opgeslagen wijzigingen gaan verloren. Weet je zeker dat je door wilt gaan?",
            "ConfirmDelete": "Weet je zeker dat je de rij wilt verwijderen?",
            CancelEdit: "Weet je zeker dat je de aanpassingen ongedaan wilt maken?",
            "ChooseColumns": "Kies kolom",
            "SearchColumns": "zoek kolommen",
            "Matchs": "Geen overeenkomsten gevonden",
            "FilterButton": "Filter",
            "ClearButton": "Verwijder filter",
            "StartsWith": "Begint met",
            "EndsWith": "Eindigt met",
            "Contains": "Bevat",
            "Equal": "Gelijk",
            "NotEqual": "Niet gelijk",
            "LessThan": "Minder dan",
            "LessThanOrEqual": "Minder dan of gelijk",
            "GreaterThan": "Groter dan",
            "GreaterThanOrEqual": "Groter dan of gelijk aan",
            "ChooseDate": "Kies een datum",
            "EnterValue": "Voer de waarde in",
            "Copy": "Kopiëren",
            "Group": "Groeperen op deze kolom",
            "Ungroup": "Degroeperen via deze kolom",
            "autoFitAll": "Alle kolommen automatisch aanpassen",
            "autoFit": "Deze kolom automatisch aanpassen",
            "Export": "Exporteren",
            "FirstPage": "Eerste pagina",
            "LastPage": "Laatste pagina",
            "PreviousPage": "Vorige pagina",
            "NextPage": "Volgende pagina",
            "SortAscending": "Oplopend sorteren",
            "SortDescending": "Aflopend sorteren",
            "EditRecord": "Bewerk rij",
            "DeleteRecord": "Verwijder rij",
            "FilterMenu": "Zoeken",
            "SelectAll": "Alles selecteren",
            "Blanks": "Leeg",
            "FilterTrue": "Ja",
            "FilterFalse": "Nee",
            "NoResult": "Geen overeenkomsten gevonden",
            "ClearFilter": "Wis filter",
            "NumberFilter": "Nummerfilters",
            "TextFilter": "Tekstfilters",
            "DateFilter": "Datumfilters",
            "DateTimeFilter": "Datum / tijd-filters",
            "MatchCase": "Hoofdlettergevoelig",
            "Between": "Tussen",
            "CustomFilter": "Aangepaste filter",
            "CustomFilterPlaceHolder": "Voer waarde toe",
            "CustomFilterDatePlaceHolder": "Kies een datum",
            "AND": "EN",
            "OR": "OF",
            "ShowRowsWhere": "Toon rijen waar:",
            "NotStartsWith": "Begint niet met",
            "Like": "Lijkt op",
            "NotEndsWith": "Eindigt niet met",
            "NotContains": "Bevat geen",
            "IsNull": "Leeg",
            "NotNull": "Niet leeg",
            "IsEmpty": "Leeg",
            "IsNotEmpty": "Niet leeg",
            "AddCurrentSelection": "Huidige selectie toevoegen aan filter",
            "UnGroupButton": "Klik hier om de groep op te heffen",
            "AutoFitAll": "Alle kolommen automatisch aanpassen",
            "AutoFit": "Deze kolom automatisch aanpassen",
            "Clear": "leegmaken",
            "FilterMenuDialogARIA": "Dialoogvenster Filtermenu",
            "ExcelFilterDialogARIA": "Dialoogvenster Excel-filter",
            "DialogEditARIA": "Dialoogvenster bewerken",
            "ColumnChooserDialogARIA": "Kolomkiezer",
            "ColumnMenuDialogARIA": "Dialoogvenster Kolommenu",
            "CustomFilterDialogARIA": "Dialoogvenster Aangepast filter",
            "SortAtoZ": "Sorteer A tot Z",
            "SortZtoA": "Sorteer Z naar A",
            "SortByOldest": "Sorteren op oudste",
            "SortByNewest": "Sorteren op nieuwste",
            "SortSmallestToLargest": "Sorteer van klein naar groot",
            "SortLargestToSmallest": "Sorteer van groot naar klein",
            "Sort": "Soort",
            "FilterDescription": "Druk op Alt Omlaag om het filtermenu te openen",
            "SortDescription": "Druk op Enter om te sorteren",
            "ColumnMenuDescription": "Druk op Alt Omlaag om Kolommenu te openen",
            "GroupDescription": "Druk op Ctrl spatie om te groeperen",
            "ColumnHeader": " kolomkop ",
            "TemplateCell": " is sjabloon cel",
            "CommandColumnAria": "is Command kolom kolomkop ",
            "DialogEdit": "Dialoogvenster bewerken",
            "ClipBoard": "klembord",
            "GroupButton": "Groepeersknop",
            "UnGroupAria": "knop groep opheffen",
            "GroupSeperator": "Scheidingsteken voor de gegroepeerde kolommen",
            "UnGroupIcon": "de groepering van de gegroepeerde kolom opheffen ",
            "GroupedSortIcon": "sorteer de gegroepeerde kolom ",
            "GroupedDrag": "Sleep de gegroepeerde kolom",
            "GroupCaption": " is group een cel",
            "CheckBoxLabel": "selectievakje",
            "Expanded": "uitbreiden",
            "Collapsed": "Inklappen",
            "SelectAllCheckbox": "Selecteer Alles",
            "SelectRow": "Selecteer rij"
        },
        "pager": {
            "currentPageInfo": "{0} van {1} pagina's",
            "totalItemsInfo": "({0} resultaten)",
            "firstPageTooltip": "Ga naar de eerste pagina",
            "lastPageTooltip": "Ga naar de laatste pagina",
            "nextPageTooltip": "Ga naar de volgende pagina",
            "previousPageTooltip": "Ga naar de vorige pagina",
            "nextPagerTooltip": "Ga naar de volgende pager pagina",
            "previousPagerTooltip": "Ga naar vorige pager pagina",
            "pagerDropDown": "Resultaten per pagina",
            "pagerAllDropDown": "resultaten",
            "All": "Alles",
            "totalItemInfo": "({0} resultaten)",
            "Container": "Pager container",
            "Information": "Pager informatie",
            "ExternalMsg": "Extern bericht",
            "Page": "Pagina ",
            "Of": " van ",
            "Pages": " Pagina's"
        },
    }
});

@Component({
    selector: "app-generic-table",
    templateUrl: "./generic-table.component.html",
    styleUrls: [ "./generic-table.component.scss" ]
})
export class GenericTableComponent<T> implements AfterViewInit, OnDestroy {
    public get toolbar(): IGenericTableToolbarItem[] {
        return this.toolbar;
    }

    @Input()
    public set toolbar(value: IGenericTableToolbarItem[]) {
        this.toolbarItems = value.map((t) => {
            if (t instanceof GenericTableToolbarSearchItem) {
                return "Search";
            }
            if (t instanceof GenericTableToolbarColumnChooserItem) {
                return "ColumnChooser";
            }

            return {
                id: t.id,
                align: t.assign,
                click: t.action,
                prefixIcon: t.icon,
                text: t.title,
                disabled: t.disabled
            } as (ItemModel | ToolbarItems);
        });
    }

    @Input()
    public headers: IGenericTableHeader<T>[] = [];

    @Input()
    public dataSource: T[] = [];

    @Output()
    public recordDoubleClickEvent: EventEmitter<T> = new EventEmitter<T>();

    @Output()
    public recordClickEvent: EventEmitter<T> = new EventEmitter<T>();

    @Output()
    public rowDataBound: EventEmitter<GenericTableRowBindingEvent<T>> = new EventEmitter<GenericTableRowBindingEvent<T>>();

    public get options(): IGenericTableOptions {
        return this.tableOptions;
    }

    @Input()
    public set options(values: IGenericTableOptions) {
        this.tableOptions.setOptions(values);
        if (this.grid != null) {
            this.setGridOptions();
        }
    }

    @ViewChild("table")
    protected grid: GridComponent;

    protected tableOptions: GenericTableOptions = new GenericTableOptions();

    private toolbarItems: (ItemModel | ToolbarItems)[] = [ "Search" ];

    private readonly filterSettings: FilterSettingsModel = {
        type: "Excel",
    };

    private readonly componentDestroyed: Subject<void> = new Subject();

    public ngAfterViewInit(): void {
        const recordDoubleClickEvent: EventEmitter<RecordDoubleClickEventArgs> = this.grid.recordDoubleClick;
        recordDoubleClickEvent.pipe(takeUntil(this.componentDestroyed)).subscribe((e: RecordDoubleClickEventArgs) => this.recordDoubleClick(e));
        const recordClickEvent: EventEmitter<RecordClickEventArgs> = this.grid.recordClick;
        recordClickEvent.pipe(takeUntil(this.componentDestroyed)).subscribe((e: RecordClickEventArgs) => this.recordClick(e));
        const commandClickEvent: EventEmitter<CommandClickEventArgs> = this.grid.commandClick;
        commandClickEvent.pipe(takeUntil(this.componentDestroyed)).subscribe((e: CommandClickEventArgs) => this.commandClick(e));

        this.grid.filterSettings = this.filterSettings;
        this.grid.toolbar = this.toolbarItems;

        this.grid.pageSettings.pageSize = this.tableOptions.defaultPageSize;
        setTimeout(() => this.setPageSize(), 250);
    }

    public ngOnDestroy(): void {
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    public refresh(): void {
        this.grid.refresh();
        this.setPageSize();
    }

    public getSelectedEntries(): T[] | null {
        const entities: T[] = [];

        const rows = this.grid.getSelectedRows();
        if (rows.length > 0) {
            for (const row of rows) {
                const record: T = this.grid.getRowInfo(row).rowData as T;
                entities.push(record);
            }

            return entities;
        }

        return null;
    }

    protected trackBy(_index: number, item: IGenericTableHeader<T>): string {
        return item.headerText;
    }

    protected convertCommands(item: IGenericTableHeader<T>): CommandModel[] | null {
        return item.commands?.map((c) => ({
            type: "None",
            title: c.title,
            buttonOption: {
                cssClass: "e-flat",
                iconCss: `e-icons ${c.icon}`
            },
        } as CommandModel)) ?? null;
    }

    protected onRowBinding(eventArgs: RowDataBoundEventArgs): void {
        this.rowDataBound.emit({
            data: eventArgs.data as T,
            row: eventArgs.row,
            rowHeight: eventArgs.rowHeight
        });
    }

    @HostListener("window:resize")
    private setPageSize(): void {
        if (this.tableOptions.shouldAutosizePagesize) {
            const rowHeight: number = this.grid.getRowHeight();
            const gridContent: HTMLElement | null = this.grid.element.querySelector<HTMLElement>(".e-content");

            if (gridContent == null) {
                console.warn("Could not find gridContent while it should exist, maybe because of navigating?");
                return;
            }

            this.grid.pageSettings.pageSize = Math.floor(gridContent.offsetHeight / rowHeight);
        }
    }

    private recordDoubleClick(args: RecordDoubleClickEventArgs): void {
        const rowData: T = args.rowData as T;
        this.recordDoubleClickEvent.emit(rowData);
    }

    private recordClick(args: RecordClickEventArgs): void {
        const rowData: T = args.rowData as T;
        this.recordClickEvent.emit(rowData);
    }

    private commandClick(args: CommandClickEventArgs): void {
        const rowData: T = args.rowData as T;
        let command: IGenericTableHeaderCommand<T> | null = null;

        for (const header of this.headers) {
            if (header.commands == null) {
                continue;
            }

            if (args.commandColumn != null) {
                command = header.commands.find((c) => c.title === args.commandColumn!.title) ?? null;
                if (command != null) {
                    break;
                }
            }
        }

        if (command == null) {
            throw new Error("Could not find the correct table command!");
        }

        command.action(rowData);
    }

    /**
     * Sets the options directly on the grid
     */
    private setGridOptions() {
        this.grid.pageSettings.pageSize = this.tableOptions.defaultPageSize;
    }
}
