import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ApolloQueryResult } from "@apollo/client/core";
import { gql, MutationResult, QueryRef } from "apollo-angular";
import { first, Subscription, take } from "rxjs";
import { PimApiClient } from "../../../services/apis/api/api.service";
import { DialogMode } from "../../../shared/enums/dialog-mode";
import { DialogResult } from "../../../shared/enums/dialog-result";
import { IGenericTableHeader } from "../../../shared/generic-table/generic-table-header";
import { IGenericTableToolbarItem } from "../../../shared/generic-table/generic-table-toolbar-item";
import { GenericTableToolbarColumnChooserItem } from "../../../shared/generic-table/generic-table-toolbar-item-column-chooser";
import { GenericTableToolbarSearchItem } from "../../../shared/generic-table/generic-table-toolbar-item-search";
import { GenericTableComponent } from "../../../shared/generic-table/generic-table.component";
import { SuppliersProductsEditDialogData } from "../suppliers-products-edit-dialog/suppliers-products-edit-dialog-data";
import { SuppliersProductsEditDialogResult } from "../suppliers-products-edit-dialog/suppliers-products-edit-dialog-result";
import { SuppliersProductsEditDialogComponent } from "../suppliers-products-edit-dialog/suppliers-products-edit-dialog.component";
import { SuppliersProductsUploadDialogResult } from "../suppliers-products-upload-dialog/suppliers-products-upload-dialog-result";
import { SuppliersProductsUploadDialogComponent } from "../suppliers-products-upload-dialog/suppliers-products-upload-dialog.component";
import { SuppliersProductsOverViewTable } from "./suppliers-products-overview-table";

@Component({
  selector: "app-suppliers-products-overview",
  templateUrl: "./suppliers-products-overview.component.html",
  styleUrl: "./suppliers-products-overview.component.scss"
})
export class SuppliersProductsOverviewComponent implements OnInit, OnDestroy {

    protected tableToolbar: IGenericTableToolbarItem[] = [ new GenericTableToolbarColumnChooserItem(), new GenericTableToolbarSearchItem() ];

    protected tableHeaders: IGenericTableHeader<SuppliersProductsOverViewTable>[] = [
        {
            field: "supplierName",
            headerText: "Leverancier",
            type: "string",
        },
        {
            field: "ean",
            headerText: "Ean",
            type: "string",
        },
        {
            field: "productCode",
            headerText: "Productcode",
            type: "string",
        },
        {
            field: "productDescription",
            headerText: "Productomschrijving",
            type: "string",
        },
        {
            field: "productStatus",
            headerText: "Productstatus",
            type: "string"
        },
        {
            field: "purchasePrice",
            headerText: "Aankoopprijs",
            type: "number",
            format: "C"
        },
        {
            field: "purchasePriceOverride",
            headerText: "Aankoopprijs overschrijven",
            type: "number",
            format: "C",
        },
        {
            field: "recommendedPrice",
            headerText: "Aanbevolen prijs",
            type: "number",
            format: "C",
        },
        {
            field: "recommendedPriceFactor",
            headerText: "Aanbevolen prijs factor",
            type: "number",
        },
        {
            field: "finalRecommendedPrice",
            headerText: "Berekende aanbevolen prijs",
            type: "number",
            format: "C",
        },
        {
            field: "minimalOrderAmount",
            headerText: "Minimale bestelhoeveelheid",
            type: "number",
        },
        {
            field: "bolBreakEvenPrice",
            headerText: "Bol break-even prijs",
            type: "number",
            format: "C",
        },
        {
            field: "createdAt",
            headerText: "Aangemaakt op",
            type: "datetime",
            format: "dd-MM-yyyy HH:mm"
        },
        {
            field: "changedAt",
            headerText: "Gewijzigd op",
            type: "datetime",
            format: "dd-MM-yyyy HH:mm"
        },
        {
            headerText: "Acties",
            commands: [
                {
                    title: "Openen",
                    icon: "e-search",
                    action: (entity: SuppliersProductsOverViewTable) => this.openDialog(entity)
                }
            ]
        }
    ];

    protected isLoading: boolean = true;

    protected supplierproducts: SuppliersProductsOverViewTable[] = [];

    @ViewChild("suppliersproductsTable")
    private table: GenericTableComponent<SuppliersProductsOverViewTable>;

    private queryRef: QueryRef<any>;
    private dataSubscription: Subscription;

    constructor(
        private readonly pimApiClient: PimApiClient,
        private readonly dialog: MatDialog,
        private readonly snackBar: MatSnackBar,
    ) { }

    public ngOnInit(): void {
        this.queryRef = this.pimApiClient.GetGraphqlQuery().watchQuery<any>({
            query: gql`
            {
                supplierProducts {
                    id
                    supplier {
                        name
                    }
                    product {
                        ean
                    }
                    productCode
                    productDescription
                    productStatus
                    purchasePrice
                    purchasePriceOverride
                    recommendedPrice
                    recommendedPriceFactor
                    finalRecommendedPrice
                    minimalOrderAmount
                    bolBreakEvenPrice
                    createdAt
                    changedAt
                }
            }`,
            pollInterval: 60_000, // 1 minute
        });
        this.dataSubscription = this.queryRef.valueChanges.subscribe({
            next: (result: ApolloQueryResult<any>) => {
                if (result.errors != null) {
                    console.error(`Graphql error while fetching: ${result.errors}`);
                }

                this.isLoading = result.loading;
                this.supplierproducts = (result.data.supplierProducts as []).map((sp: any) => ({
                    id: sp.id,
                    supplierName: sp.supplier.name,
                    ean: sp.product.ean,
                    productCode: sp.productCode,
                    productDescription: sp.productDescription,
                    productStatus: sp.productStatus,
                    purchasePrice: sp.purchasePrice,
                    purchasePriceOverride: sp.purchasePriceOverride,
                    recommendedPrice: sp.recommendedPrice,
                    recommendedPriceFactor: sp.recommendedPriceFactor,
                    finalRecommendedPrice: sp.finalRecommendedPrice,
                    minimalOrderAmount: sp.minimalOrderAmount,
                    bolBreakEvenPrice: sp.bolBreakEvenPrice,
                    createdAt: new Date(sp.createdAt),
                    changedAt: new Date(sp.changedAt),
                } as SuppliersProductsOverViewTable));
            },
            error: (err: any) => {
                console.error(`Graphql error before fetching: ${err}`);
            }
        });
    }

    public ngOnDestroy(): void {
        this.dataSubscription?.unsubscribe();
    }

    protected openDialog(entity: SuppliersProductsOverViewTable) {
         const dialogData: SuppliersProductsEditDialogData = {
            mode: DialogMode.Edit,
            id: entity.id,
            purchasePriceOverride: entity.purchasePriceOverride,
            recommendedPriceFactor: entity.recommendedPriceFactor,
        };

        const dialog = this.dialog.open(SuppliersProductsEditDialogComponent, {
            minWidth: "300px",
            data: dialogData,
        });
        dialog.afterClosed().pipe(take(1)).subscribe((result: SuppliersProductsEditDialogResult) => {
            if (result != null && result.dialogResultMode === DialogResult.Updated) {
                const sp = this.supplierproducts.find((supplierProduct) => supplierProduct.id === result.id);

                if (sp == null) {
                    console.error(`Supplier product with id ${result.id} not found.`);
                    this.snackBar.open("Er is iets misgegaan bij het opslaan.", "", {
                        duration: 3000
                    });
                    return;
                }

                this.pimApiClient.GetGraphqlQuery().mutate({
                    mutation: gql`
                    mutation updateSupplierProduct($input: UpdateSupplierProductInput!) {
                        updateSupplierProduct(input: $input) {
                            supplierProductResourceResult {
                                id
                            }
                        }
                    }`,
                    variables: {
                        input: {
                            id: sp.id,
                            resource: {
                                productCode: sp.productCode,
                                productDescription: sp.productDescription,
                                purchasePrice: sp.purchasePrice,
                                purchasePriceOverride: result.purchasePriceOverride,
                                recommendedPrice: sp.recommendedPrice,
                                recommendedPriceFactor: result.recommendedPriceFactor,
                                minimalOrderAmount: sp.minimalOrderAmount,
                                productStatus: sp.productStatus,
                            }
                        }
                    },
                })
                    .pipe(first())
                    .subscribe({
                        next: (result: MutationResult<unknown>) => {
                            this.queryRef.refetch();
                            this.table.refresh();
                        },
                        error: (err: any) => {
                            console.error(`Graphql error before updating: ${err}`);
                            this.snackBar.open("Er is iets misgegaan bij het opslaan.", "", {
                                duration: 3000
                            });
                        }
                });
            }
        });
    }

    protected import() {
        const uploadDialogRef: MatDialogRef<SuppliersProductsUploadDialogComponent, SuppliersProductsUploadDialogResult | null> = this.dialog.open(SuppliersProductsUploadDialogComponent, {
            minWidth: "300px",
        });

        uploadDialogRef.afterClosed().pipe(first()).subscribe((result: SuppliersProductsUploadDialogResult | null) => {
            if (result?.dialogResultMode === DialogResult.Created) {
                this.queryRef.refetch();
                this.table.refresh();
                this.snackBar.open("Producten zijn succesvol geüpload", "Sluiten", {
                    duration: 3000,
                });
            }
        })
    }

    protected exportVendit() {
        this.pimApiClient.ExportVendit().pipe(first()).subscribe((blob: Blob) => {
            const file: File = new File([ blob ], "Vendit.csv", { type: blob.type });

            const url: string = window.URL.createObjectURL(file);

            window.open(url, "_blank");

            setTimeout(() => {
                window.URL.revokeObjectURL(url);
            }, 10_000);
        })
    }

    // private formatDate(date: string): string {
    //     return new Intl.DateTimeFormat("nl-NL", {
    //         dateStyle: "short",
    //         timeStyle: "short",
    //     }).format(new Date(date));
    // }

    //  private formatMoney(money: number): string {
    //     return new Intl.NumberFormat("nl-NL", {
    //         style: "currency",
    //         currency: "EUR",
    //     }).format(money);
    // }
}
