<ng-container *ngIf="!isLoading; else loadingTemplate">
    <app-analytics></app-analytics>
    <ng-container *ngIf="isAuthorized; else unauthorizedTemplate">
        <app-toolbar [showNavbarToggle]="showMobileView" (toggleSideNav)="drawer.toggle()"></app-toolbar>

        <mat-drawer-container class="main-container">
            <mat-drawer #drawer [mode]="showMobileView ? 'over' : 'side'" opened>
                <app-navbar></app-navbar>
            </mat-drawer>

            <mat-drawer-content>
                <div class="main-page">
                    <router-outlet></router-outlet>
                </div>
            </mat-drawer-content>
        </mat-drawer-container>
    </ng-container>
</ng-container>

<ng-template #loadingTemplate>
    <div class="screen-center">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>

<ng-template #unauthorizedTemplate>
    <router-outlet></router-outlet>
</ng-template>
