import { Component, Inject, HostListener, OnInit, ViewChild } from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import { AuthService, AUTH_SERVICE } from "./services/auth/auth.service";
import { StyleService, STYLE_SERVICE } from "./services/style.service";
import { THEMING_SERVICE, ThemingService } from "./services/theming.service";
import { WebStyles } from "./services/apis/identity/models/tenant-public-resource-result";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: [ "./app.component.scss" ]
})
export class AppComponent implements OnInit {

    protected isLoading: boolean;
    protected isAuthorized: boolean;
    protected showMobileView: boolean;

    @ViewChild("drawer")
    protected drawer: MatDrawer;

    private maxTabletSize: number = 768;

    public constructor(
        @Inject(AUTH_SERVICE) authService: AuthService,
        @Inject(STYLE_SERVICE) private styleService: StyleService,
        @Inject(THEMING_SERVICE) private themeService: ThemingService
    ) {
        this.isLoading = true;
        this.isAuthorized = false;

        authService.init().then((unauthorized: boolean) => {
            if (!unauthorized) {
                this.isLoading = false;
                return;
            }
            this.isAuthorized = true;
            this.styleService.getWebStyles().then((result: WebStyles[]) => {
                if (result != null) {
                    this.themeService.updateStyling(result);
                }
                this.isLoading = false;
            });
        });
    }

    public ngOnInit(): void {
        this.showMobileView = window.innerWidth <= this.maxTabletSize;
    }

    @HostListener("window:resize")
    protected onResize(): void {
        if (this.drawer != null) {
            this.showMobileView = window.innerWidth <= this.maxTabletSize;

            if (!this.showMobileView && !this.drawer.opened) {
                this.drawer.open();
            } else if (this.showMobileView && this.drawer.opened) {
                this.drawer.close();
            }
        }
    }
}
