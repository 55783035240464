export interface TenantPublicResourceResult {
    id: string;
    name: string;
    termsOfService: string;
    webStyles: WebStyles[];
}

export interface WebStyles {
    style: WebStyle;
    red: number;
    green: number;
    blue: number;
}

export enum WebStyle {
    Primary = "Primary",
    Accent = "Accent",
    Warning = "Warning",
    Success = "Success",
    Background = "Background",
}
