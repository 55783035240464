import { Injectable, InjectionToken, OnDestroy } from "@angular/core";
import { Key } from "ts-key-enum";

@Injectable({
  providedIn: "root"
})
export class KeyboardService implements OnDestroy {
    private activeKeyBoardEvents: Map<Key, KeyboardEvent> = new Map();

    public constructor() {
        document.addEventListener("onkeydown", this.OnKeyDown);
        document.addEventListener("onkeyup", this.OnKeyUp);
    }

    public ngOnDestroy(): void {
        document.removeEventListener("onkeydown", this.OnKeyDown);
        document.addEventListener("onkeyup", this.OnKeyUp);
    }

    public IsKeyPressed(key: Key): boolean {
        return this.activeKeyBoardEvents.has(key);
    }

    private OnKeyDown(ev: KeyboardEvent): void {
        this.activeKeyBoardEvents.set(<Key>ev.key, ev);
    }

    private OnKeyUp(ev: KeyboardEvent): void {
        this.activeKeyBoardEvents.delete(<Key>ev.key);
    }
}

export const KEYBOARD_SERVICE: InjectionToken<KeyboardService> = new InjectionToken("KEYBOARD_SERVICE");
