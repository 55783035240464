<ejs-grid
    #table
    height="100%"

    [dataSource]="dataSource"

    [allowPaging]="tableOptions.allowPaging"
    [allowSorting]="tableOptions.allowSorting"
    [allowFiltering]="tableOptions.allowFiltering"
    [allowMultiSorting]="tableOptions.allowMultiSorting"
    [allowResizing]="tableOptions.allowResizing"
    [showColumnChooser]="true"

    (rowDataBound)="onRowBinding($event)"
>
    <e-columns>
        <e-column *ngFor="let header of headers; trackBy:trackBy" [field]="header.field" [headerText]="header.headerText" [format]="header.format" [type]="header.type" [commands]="convertCommands(header)"></e-column>
    </e-columns>
</ejs-grid>
