import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ApiModule } from "./apis/api.module";
import { AuthCallbackComponent } from "./auth/auth-callback/auth-callback.component";
import { AuthSilentCallbackComponent } from "./auth/auth-silent-callback/auth-silent-callback.component";
import { AUTH_SERVICE, AuthService } from "./auth/auth.service";
import { KEYBOARD_SERVICE, KeyboardService } from "./keyboard.service";
import { GraphQLModule } from "../shared/graphql.module";

@NgModule({
    declarations: [
        AuthCallbackComponent,
        AuthSilentCallbackComponent
    ],
    imports: [
        CommonModule,
        ApiModule,
        GraphQLModule,
    ],
    providers:
    [
        { provide: AUTH_SERVICE, useClass: AuthService },
        { provide: KEYBOARD_SERVICE, useClass: KeyboardService }
    ],
    exports: [ ApiModule ]
})
export class ServiceModule { }
