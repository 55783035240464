import { Inject, Injectable, InjectionToken } from "@angular/core";
import { AUTH_SERVICE, AuthService } from "./auth/auth.service";
import { IdentityClient } from "./apis/identity/identity.service";
import { lastValueFrom } from "rxjs";
import { TenantPublicResourceResult, WebStyle, WebStyles } from "./apis/identity/models/tenant-public-resource-result";

@Injectable({
    providedIn: "root"
})
export class StyleService {
    private tenantId: string = "";
    private tenantName: string = "";

    public constructor(
        private identityClient: IdentityClient,
        @Inject(AUTH_SERVICE) private authService: AuthService,
    ) {
    }

    public async getWebStyles(): Promise<WebStyles[]> {
        const tenantName = this.authService.getTenantName();

        if (tenantName != null) {
            const response: TenantPublicResourceResult | null = await lastValueFrom(this.identityClient.GetTenantPublicResourceResult(tenantName));

            if (response != null) {
                this.tenantId = response.id;
                this.tenantName = response.name;
                return response.webStyles;
            }
        }

        throw new Error("Could not retrieve the tenant public resource.");
    }

    public getLogoUrl(): string {
        return this.identityClient.GetTenantLogoUrl(this.tenantId);
    }

    public getTenantName(): string {
        return this.tenantName;
    }

    public getMaterialColor(webStyle: WebStyle): string {
        switch (webStyle) {
            case WebStyle.Background:
                return "var(--background-color)";

            case WebStyle.Primary:
                return "var(--primary-color)";

            case WebStyle.Accent:
                return "var(--accent-color)";

            case WebStyle.Warning:
                return "var(--warning-color)";

            case WebStyle.Success:
                return "var(--success-color)";

            default:
                throw new Error("The presented WebStyle does not comply with the styles.");
        }
    }

    public webStyleToMaterialColor(webStyle: WebStyle): string {
        switch (webStyle) {
            case WebStyle.Warning:
                return "warn";
            default:
                return webStyle.toLowerCase();
        }
    }
}

export const STYLE_SERVICE: InjectionToken<StyleService> = new InjectionToken("STYLE_SERVICE");
