import { AuthService, AUTH_SERVICE } from "src/app/services/auth/auth.service";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root"
})
export class AuthGuardService {
    constructor(
		@Inject(AUTH_SERVICE) private authService: AuthService,
		private router: Router
    ) { }

    public async canActivate(requiredScopes: string[]): Promise<boolean> {
        if (
            await this.authService.isLoggedIn() &&
            this.authService.hasScope("pim")
        ) {
            let hasScopes = true;
            for (const scope of requiredScopes) {
                if (!this.authService.hasScope(scope)) {
                    hasScopes = false;
                    break;
                }
            }

            if (hasScopes) {
                return true;
            }
        }

        try {
            await this.authService.startAuthentication();
        } catch (error) {
            console.error(error);
            this.router.navigate([ 401 ]);
        }
        return false;
    }
}
