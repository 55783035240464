
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuComponent, MenuEventArgs } from "@syncfusion/ej2-angular-navigations";
import { first, Subject, timer } from "rxjs";
import { AuthService, AUTH_SERVICE } from "src/app/services/auth/auth.service";
import { StyleService, STYLE_SERVICE } from "src/app/services/style.service";
import { IMenuItem } from "./IMenuItem";

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: [ "./navbar.component.scss" ]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
    protected image: string;
    protected isNavigationBarCollapsed: boolean;
    protected menuItems: IMenuItem[] = [];

    @ViewChild(MenuComponent)
    protected menu: MenuComponent;

    private componentDestroyed: Subject<void> = new Subject();

    public constructor(
        @Inject(AUTH_SERVICE) private authService: AuthService,
        @Inject(STYLE_SERVICE) private styleService: StyleService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    public ngOnInit(): void {
        this.isNavigationBarCollapsed = true;
        this.image = this.styleService.getLogoUrl();

        this.createMenuItems();
    }

    public ngAfterViewInit(): void {
        timer(250).pipe(first()).subscribe(() => {
            const currentMenuItem = this.getCurrentTopLevelMenuItem();

            if (currentMenuItem) {
                const menuItem = this.menu.element.querySelector<HTMLElement>(`#${currentMenuItem.id}`);
                if (menuItem != null) {
                    this.setCurrentSelectedRoute(menuItem, currentMenuItem.parent ?? null);
                }
            } else {
                console.error("Could not find current currentMenuItem based on route!");
            }
        });
    }

    public ngOnDestroy(): void {
	    this.componentDestroyed.next();
	    this.componentDestroyed.complete();
    }

    protected logout(): void {
        this.authService.logout();
    }

    protected onMenuItemSelect(event: MenuEventArgs) {
        const menuItem: IMenuItem = event.item;

        if (menuItem.url !== "") {
            console.error("Do not use menuItem url!");
            return;
        }

        if (menuItem.path != null) {
            this.setCurrentSelectedRoute(event.element, menuItem.parent ?? null);
            this.router.navigate([ menuItem.path ], { relativeTo: this.route });
        }
    }

    private getCurrentTopLevelMenuItem(): IMenuItem | null {
        // Get the current url without the first slash
        const currentUrl: string = this.router.url.toString().substring(1);

        // Search for the current menu item based on the current url recursively
        let currentMenuItem = this.searchCurrentTopLevelMenuItemRecursively(currentUrl, this.menuItems);

        if (currentMenuItem == null) {
            // If the current menu item is not found, search for the current menu item based on the current url (assume it contains an id at the end)
            const currentUrlWithoutIdSuffix = currentUrl.substring(0, currentUrl.lastIndexOf("/"));
            currentMenuItem = this.searchCurrentTopLevelMenuItemRecursively(currentUrlWithoutIdSuffix, this.menuItems);
        }

        return currentMenuItem;
    }

    private searchCurrentTopLevelMenuItemRecursively(currentUrl: string, menuItems: IMenuItem[]): IMenuItem | null {
        for (const menuItem of menuItems) {
            if (menuItem.path === currentUrl) {
                return menuItem;
            }

            if (menuItem.items) {
                const result = this.searchCurrentTopLevelMenuItemRecursively(currentUrl, menuItem.items);
                if (result) {
                    // when the result is found, return its parent (when every parent does this, we get the top level parent)
                    return menuItem;
                }
            }
        }

        return null;
    }

    private setCurrentSelectedRoute(htmlElement: HTMLElement, parent: IMenuItem | null) {
        if (htmlElement.parentElement != null && htmlElement.parentElement.classList.contains("e-menu")) {
            this.clearCurrentSelectedRoute(htmlElement.parentElement.children);

            htmlElement.classList.add("currentSelectedRoute");
        } else if (parent != null) {
            this.clearCurrentSelectedRoute(this.menu.element.children);
            const parentElement = this.menu.element.querySelector(`#${parent.id}`);
            if (parentElement != null) {
                parentElement.classList.add("currentSelectedRoute");
            }
        } else {
            throw new Error(`Invalid navigation element selected: ${htmlElement}`);
        }
    }

    private clearCurrentSelectedRoute(htmlCollection: HTMLCollection) {
        for (let i = 0; i < htmlCollection.length; i++) {
            const element: Element = htmlCollection[i];
            if (element.hasChildNodes()) {
                this.clearCurrentSelectedRoute(element.children);
            }
            element.classList.remove("currentSelectedRoute");
        }
    }

    private createMenuItems(): void {
        const menuItems: IMenuItem[] = []

        if (
            this.authService.hasScope("pim:products:r") &&
            this.authService.hasScope("pim:suppliers:r")
        ) {
            menuItems.push({
                id: "navbar-products",
                text: "Producten",
                iconCss: "interests",
                path: "products",
            });
        }

        if (this.authService.hasScope("pim:suppliers:r")) {
            menuItems.push({
                id: "navbar-suppliers",
                text: "Leveranciers",
                iconCss: "factory",
                path: "suppliers",
            });
        }

        if (this.authService.hasScope("pim:supplier-products:r")) {
            menuItems.push({
                id: "navbar-supplier-products",
                text: "Leverancier producten",
                iconCss: "shelves",
                path: "suppliers/products",
            });
        }

        this.menuItems = menuItems;
    }
}
