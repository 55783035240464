import { NgModule, inject } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ProductsOverviewComponent } from "./pages/products/products-overview/products-overview.component";
import { AuthCallbackComponent } from "./services/auth/auth-callback/auth-callback.component";
import { AuthSilentCallbackComponent } from "./services/auth/auth-silent-callback/auth-silent-callback.component";
import { UnauthorizedComponent } from "./shared/errorPages/unauthorized/unauthorized.component";
import { AuthGuardService } from "./shared/guards/auth.guard.service";
import { GuardsModule } from "./shared/guards/guards.module";
import { SuppliersOverviewComponent } from "./pages/suppliers/suppliers-overview/suppliers-overview.component";
import { SuppliersProductsOverviewComponent } from "./pages/suppliers-products/suppliers-products-overview/suppliers-products-overview.component";

@NgModule({
    imports: [
        GuardsModule,
        RouterModule.forRoot([
            { path: "", redirectTo: "suppliers/products", pathMatch: "full" },
            { path: "products", component: ProductsOverviewComponent, canActivate: [ () => inject(AuthGuardService).canActivate([ "pim:products:r", "pim:suppliers:r" ]) ] },
            { path: "suppliers", component: SuppliersOverviewComponent, canActivate: [ () => inject(AuthGuardService).canActivate([ "pim:suppliers:r" ]) ] },
            { path: "suppliers/products", component: SuppliersProductsOverviewComponent, canActivate: [ () => inject(AuthGuardService).canActivate([ "pim:supplier-products:r" ]) ] },
            { path: "auth-callback", component: AuthCallbackComponent },
            { path: "auth-silent-callback", component: AuthSilentCallbackComponent },
            { path: "401", component: UnauthorizedComponent },
            { path: "**", redirectTo: "" }
        ]),
    ],
    exports: [ RouterModule ]
})
export class AppRoutingModule {
}
