import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogState } from "@angular/material/dialog";
import { AppConfig } from "src/app/services/config/models/app-config";
import { StyleService } from "src/app/services/style.service";
import { WebStyle } from "../../services/apis/identity/models/tenant-public-resource-result";
import { DialogData } from "../generic-dialog/dialog-data";
import { GenericDialogComponent } from "../generic-dialog/generic-dialog.component";


@Injectable({ providedIn: "root" })
export class GenericErrorHandler implements ErrorHandler {

    private dialogRef: MatDialogRef<GenericDialogComponent> | null;
    private readonly genericErrorText: string = "Er is iets misgegaan bij het uitvoeren van uw laatste actie.";
    private readonly showDialogOnError: boolean;

    public constructor(
        private dialog: MatDialog,
        private zone: NgZone,
        private styleService: StyleService,
        config: AppConfig
    ) {
        this.showDialogOnError = config.showDialogOnError;
    }

    public handleError(error: any): void {
        if (this.showDialogOnError && this.dialogRef?.getState() !== MatDialogState.OPEN) {
            // only open one error dialog at a time
            this.handleGlobalErrors(error);
        }
    }

    private handleGlobalErrors(error: any) {
        if (error instanceof Error) {
            this.openErrorDialog([ this.genericErrorText, error.message ]);
        }
    }

    private openErrorDialog(errorMessages: string[]) {
        this.zone.run(() => {
            const data: DialogData = {
                title: "Error",
                message: errorMessages,
                icon: "error",
                iconColor: this.styleService.getMaterialColor(WebStyle.Warning),
                primaryButtonText: "Sluiten",
                primaryButtonColor: WebStyle.Primary,
                primaryButtonAction: () => { /* Ignored */ }
            };

            this.dialogRef = this.dialog.open(GenericDialogComponent, { data });
        });
    }
}
