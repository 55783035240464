<div mat-dialog-title>
    Aanpassen
</div>
<div mat-dialog-content>
    <form [formGroup]="form" (ngSubmit)="submit()">
        <mat-grid-list cols="1" rowHeight="80px">
            <mat-grid-tile>
                <mat-checkbox [formControl]="form.controls.orderInWarehouse">Bestellen bij magazijn</mat-checkbox>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field>
                    <mat-label>Voorkeursleverancier</mat-label>
                    <mat-select [formControl]="form.controls.favoriteSupplierId">
                        <mat-option *ngFor="let s of data.suppliers" [value]="s.id">{{s.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.controls.favoriteSupplierId.touched && form.controls.favoriteSupplierId.invalid" color="warn">
                        De Voorkeursleverancier is ongeldig.
                    </mat-error>
                </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>

        <div class="action-buttons">
            <button *ngIf="isReadOnly === false" mat-raised-button type="submit" color="primary">Opslaan</button>
            <button mat-raised-button [mat-dialog-close]>Annuleren</button>
        </div>
    </form>
</div>
