import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { DateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import * as Sentry from "@sentry/angular-ivy";
import { MaterialCssVarsModule } from "angular-material-css-vars";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { IDENTITY_API_BASE_URL } from "./services/apis/identity/identity.service";
import { AppConfig } from "./services/config/models/app-config";
import { ServiceModule } from "./services/services.module";
import { STYLE_SERVICE, StyleService } from "./services/style.service";
import { THEMING_SERVICE, ThemingService } from "./services/theming.service";
import { CustomDateAdapter } from "./shared/adapters/custom-date-adapter";
import { ErrorHandlerModule } from "./shared/error-handlers/error-handler.module";
import { ProductsModule } from "./pages/products/products.module";
import { SuppliersModule } from "./pages/suppliers/suppliers.module";
import { SuppliersProductsModule } from "./pages/suppliers-products/suppliers-products.module";
import { PIM_API_BASE_URL } from "./services/apis/api/api.service";

@NgModule({
    declarations: [ AppComponent ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule,
        ErrorHandlerModule,
        ServiceModule,
        MaterialCssVarsModule.forRoot({}),
        HttpClientModule,
        SuppliersModule,
        ProductsModule,
        SuppliersProductsModule,
    ],
    providers: [
        { provide: IDENTITY_API_BASE_URL, deps: [ AppConfig ], useFactory: (appConfig: AppConfig) => appConfig.Identity.BaseUrl },
        { provide: PIM_API_BASE_URL, deps: [ AppConfig ], useFactory: (appConfig: AppConfig) => appConfig.Api.BaseUrl },
        { provide: STYLE_SERVICE, useClass: StyleService },
        { provide: THEMING_SERVICE, useClass: ThemingService },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => Promise.resolve(),
            deps: [ AppConfig, Sentry.TraceService ],
            multi: true,
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: "nl-NL"
        },
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter
        }
    ],
    bootstrap: [ AppComponent ]
})
export class AppModule {
}
