import { NgModule } from "@angular/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { ColumnChooserService, CommandColumnService, ContextMenuService, FilterService, GridModule, PageService, ResizeService, SortService, ToolbarService } from "@syncfusion/ej2-angular-grids";
import { CoreModule } from "../core/core.module";
import { ErrorListComponent } from "./error-list/error-list.component";
import { UnauthorizedComponent } from "./errorPages/unauthorized/unauthorized.component";
import { GenericDialogComponent } from "./generic-dialog/generic-dialog.component";
import { GenericLegendComponent } from "./generic-legend/generic-legend.component";
import { GENERIC_LEGEND_SERVICE, GenericLegendService } from "./generic-legend/services/generic-legend.service";
import { GenericTableComponent } from "./generic-table/generic-table.component";

@NgModule({
    imports: [
        CoreModule,
        MatGridListModule,
        MatDatepickerModule,
        GridModule,
        MatExpansionModule,
        MatGridListModule,
        MatSnackBarModule,
        MatCheckboxModule,
    ],
    declarations: [
        UnauthorizedComponent,
        ErrorListComponent,
        GenericDialogComponent,
        GenericTableComponent,
        GenericLegendComponent,
    ],
    providers: [
        PageService,
        SortService,
        FilterService,
        ToolbarService,
        CommandColumnService,
        ContextMenuService,
        ColumnChooserService,
        ResizeService,
        { provide: GENERIC_LEGEND_SERVICE, useClass: GenericLegendService }
    ],
    exports: [
        ErrorListComponent,
        GenericTableComponent,
        GenericLegendComponent,
    ]
})
export class SharedModule { }
