<div *ngIf="this.isLoading" class="center-spinner">
    <mat-spinner></mat-spinner>
</div>

<div class="full-page-table-container">
    <app-generic-table
        #suppliersTable

        [toolbar]="tableToolbar"
        [headers]="tableHeaders"
        [dataSource]="suppliers"

        (recordDoubleClickEvent)="openDialog($event)"
    ></app-generic-table>
</div>
