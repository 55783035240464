import { Component } from "@angular/core";

@Component({
    selector: "app-unauthorized",
    templateUrl: "./unauthorized.component.html",
    styleUrls: [ "./unauthorized.component.scss" ]
})
export class UnauthorizedComponent {
    constructor() {
        console.warn("UnauthorizedComponent called");
    }
}
