<div mat-dialog-title>
    Importeren
</div>
<div mat-dialog-content>
    <div>
        <app-error-list [errors]="errors"></app-error-list>
        <div *ngIf="!isLoading; else loadingTemplate">
            <input class="hidden" type="file" (change)="submit($event)" #hiddenFileInput
                accept="text/csv">
            <button mat-raised-button (click)="hiddenFileInput.click()"><mat-icon>cloud_upload</mat-icon>Selecteer bestand</button>
        </div>
    </div>
    <ng-template #loadingTemplate>
        <div class="content-spinner">
            <mat-spinner diameter="150"></mat-spinner>
        </div>
    </ng-template>
</div>
