import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { StyleService, STYLE_SERVICE } from "src/app/services/style.service";
import { DialogData } from "./dialog-data";
import { WebStyle } from "../../services/apis/identity/models/tenant-public-resource-result";

@Component({
    selector: "app-generic-dialog",
    templateUrl: "./generic-dialog.component.html",
    styleUrls: [ "./generic-dialog.component.scss" ]
})
export class GenericDialogComponent {
    protected numberOfButtons: number;
    protected primaryButtonColor: string;
    protected secondaryButtonColor: string;

    public constructor(
        protected dialogRef: MatDialogRef<GenericDialogComponent>,
        @Inject(MAT_DIALOG_DATA) protected data: DialogData,
        @Inject(STYLE_SERVICE) styleService: StyleService
    ) {
        document.documentElement.style.setProperty("--generic-dialog-icon-color", data.iconColor);

        this.numberOfButtons = 0;
        if (data.primaryButtonAction != null) {
            this.numberOfButtons++;
            this.primaryButtonColor = styleService.webStyleToMaterialColor(data.primaryButtonColor ?? WebStyle.Primary);

            if (data.secondaryButtonAction != null) {
                this.numberOfButtons++;
                this.secondaryButtonColor = styleService.getMaterialColor(data.secondaryButtonColor ?? WebStyle.Background);
            }
        }
    }
}
