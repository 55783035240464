import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { firstValueFrom } from "rxjs";
import { PimApiClient } from "../../../services/apis/api/api.service";
import { DialogResult } from "../../../shared/enums/dialog-result";

@Component({
  selector: "app-suppliers-products-upload-dialog",
  templateUrl: "./suppliers-products-upload-dialog.component.html",
  styleUrl: "./suppliers-products-upload-dialog.component.scss"
})
export class SuppliersProductsUploadDialogComponent {

    protected isLoading: boolean;
    protected errors: string[] = [];

    constructor(
        private readonly dialogRef: MatDialogRef<SuppliersProductsUploadDialogComponent>,
        @Inject(MAT_DIALOG_DATA) protected readonly data: any,
        private readonly pimApiClient: PimApiClient,
    ) { }

    protected async submit(event: Event): Promise<void> {
        this.isLoading = true;
        this.dialogRef.disableClose = true;

        this.errors = [];

        const input: HTMLInputElement = event.target as HTMLInputElement;
        if (input.files != null && input.files.length > 0) {
            const file: File = input.files[0];

            try {
                await firstValueFrom(this.pimApiClient.Import(file));
                this.dialogRef.close({ dialogResultMode: DialogResult.Created });
            }
            catch (error) {
                console.warn(error);

                if (error instanceof Error) {
                    this.errors.push("Er zijn een of meerdere fouten opgetreden bij het importeren van het bestand:", ...error.message.split("\n"));
                }
            }
        }

        this.dialogRef.disableClose = false;
        this.isLoading = false;
    }
}
