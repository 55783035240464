import { ErrorHandler, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ERROR_HANDLERS, GlobalErrorHandler } from "./global-error-handler";
import * as Sentry from "@sentry/angular-ivy";
import { GenericErrorHandler } from "./generic-error-handler";
import { Router } from "@angular/router";
import { LogErrorHandler } from "./log-error-handler";

@NgModule({
    declarations: [],
    imports: [ CommonModule ],
    providers: [
        {
            provide: ERROR_HANDLERS,
            useExisting: LogErrorHandler,
            multi: true
        },
        {
            provide: ERROR_HANDLERS,
            useExisting: GenericErrorHandler,
            multi: true
        },
        {
            provide: ERROR_HANDLERS,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
                logErrors: false
            }),
            multi: true
        },
        {
            provide: ErrorHandler,
            useExisting: GlobalErrorHandler
        },
        {
            provide: Sentry.TraceService,
            deps: [ Router ]
        },
    ]
})
export class ErrorHandlerModule { }
