import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { AuthService, AUTH_SERVICE } from "src/app/services/auth/auth.service";
import { StyleService, STYLE_SERVICE } from "src/app/services/style.service";

@Component({
    selector: "app-toolbar",
    templateUrl: "./toolbar.component.html",
    styleUrls: [ "./toolbar.component.scss" ]
})
export class ToolbarComponent implements OnInit {

    protected image: string;
    protected isNavigationBarCollapsed: boolean;
    protected tenantName: string;

    @Input()
    protected showNavbarToggle: boolean;

    @Output()
    protected toggleSideNav: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        @Inject(STYLE_SERVICE) private styleService: StyleService
    ) { }

    public ngOnInit(): void {
        this.isNavigationBarCollapsed = true;
        this.image = this.styleService.getLogoUrl();
        this.tenantName = this.styleService.getTenantName();
    }
}
