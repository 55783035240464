import { IGenericTableOptions } from "./i-generic-table-options";

export class GenericTableOptions implements IGenericTableOptions {

    public defaultPageSize: number = 12;
    public shouldAutosizePagesize: boolean = true;

    public allowPaging: boolean = true;
    public allowSorting: boolean = true;
    public allowFiltering: boolean = true;
    public allowMultiSorting: boolean = true;
    public allowResizing: boolean = true;

    public setOptions(values: IGenericTableOptions): void {
        const keys = Object.keys(values);
        for (const key of keys) {
            if (values[key] != null) {
                this[key] = values[key];
            }
        }
    }
}
