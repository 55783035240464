import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AUTH_SERVICE, AuthService } from "../../../services/auth/auth.service";
import { DialogMode } from "../../../shared/enums/dialog-mode";
import { DialogResult } from "../../../shared/enums/dialog-result";
import { SuppliersEditDialogData } from "./suppliers-edit-dialog-data";
import { SuppliersEditDialogForm } from "./suppliers-edit-dialog-form";
import { SuppliersEditDialogResult } from "./suppliers-edit-dialog-result";

@Component({
  selector: "app-suppliers-edit-dialog",
  templateUrl: "./suppliers-edit-dialog.component.html",
  styleUrl: "./suppliers-edit-dialog.component.scss"
})
export class SuppliersEditDialogComponent {
    protected readonly form: FormGroup<SuppliersEditDialogForm>;
    protected readonly isReadOnly: boolean = true;

    constructor(
        private readonly dialogRef: MatDialogRef<SuppliersEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) protected data: SuppliersEditDialogData,
        @Inject(AUTH_SERVICE) readonly authService: AuthService,
    ) {
        if (data.mode === DialogMode.Edit && authService.hasScope("pim:suppliers:u")) {
            this.isReadOnly = false;
        }

        this.form = new FormGroup<SuppliersEditDialogForm>({
            name: new FormControl({ value: data.name, disabled: this.isReadOnly }, { validators: Validators.required, nonNullable: true }),
            purchaseDiscountInPercentage: new FormControl({ value: data.purchaseDiscountInPercentage * 100, disabled: this.isReadOnly }, { validators: [ Validators.required, Validators.min(0), Validators.max(100) ], nonNullable: true }),
        });
     }

    protected submit(): void {
        this.form.markAllAsTouched();

        if (this.form.valid) {
            const result: SuppliersEditDialogResult = {
                dialogResultMode: DialogResult.Updated,
                id: this.data.id,
                name: this.form.controls.name.value,
                purchaseDiscountInPercentage: this.form.controls.purchaseDiscountInPercentage.value / 100,
            };

            this.dialogRef.close(result);
        }
    }
}
