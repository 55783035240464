import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "../app-routing.module";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { ToolbarComponent } from "./toolbar/toolbar.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { MenuModule } from "@syncfusion/ej2-angular-navigations";
import { enableRipple } from "@syncfusion/ej2-base";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { AnalyticsComponent } from "./analytics/analytics.component";


enableRipple(true);

@NgModule({
    declarations: [
        ToolbarComponent,
        NavbarComponent,
        AnalyticsComponent,
    ],
    exports: [
        ToolbarComponent,
        NavbarComponent,
        AnalyticsComponent,

        // All modules
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatTabsModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatSelectModule,
        MatExpansionModule,
        MatSlideToggleModule,
        MatGridListModule,
        MatCheckboxModule,
        MatAutocompleteModule,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatTabsModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatSelectModule,
        MatExpansionModule,
        MatSlideToggleModule,
        MenuModule,
        MatGridListModule,
        MatCheckboxModule,
        MatAutocompleteModule,
    ]
})
export class CoreModule {
}
