<h1 mat-dialog-title>
    <div class="dialog-header">
        <mat-icon class="generic-icon">{{data.icon}}</mat-icon>
        <span>{{data.title}}</span>
    </div>
</h1>
<div mat-dialog-content>
    <p *ngFor="let line of data.message">{{line}}</p>
    <ng-container *ngIf="data.primaryButtonAction != null">
        <mat-grid-list [cols]="numberOfButtons" rowHeight="60px" gutterSize="16px">
            <mat-grid-tile colspan="1">
                <button mat-raised-button [color]="primaryButtonColor" (click)="data.primaryButtonAction()" mat-dialog-close>{{data.primaryButtonText != null ? data.primaryButtonText : "Doorgaan"}}</button>
            </mat-grid-tile>
            <ng-container *ngIf="data.secondaryButtonAction != null">
                <mat-grid-tile colspan="1">
                <button mat-raised-button [color]="data.secondaryButtonColor" (click)="data.secondaryButtonAction()" mat-dialog-close>{{data.secondaryButtonText != null ? data.secondaryButtonText : "Annuleren"}}</button>
                </mat-grid-tile>
            </ng-container>
        </mat-grid-list>
    </ng-container>
</div>
