import { Inject, Component, OnInit } from "@angular/core";
import { AuthService, AUTH_SERVICE } from "src/app/services/auth/auth.service";
import { AnalyticsConfig } from "src/app/services/config/models/analytics-config";
import { AppConfig } from "src/app/services/config/models/app-config";
import { IAnalyticsWindow } from "./i-analytics-window";

@Component({
    selector: "app-analytics",
    templateUrl: "./analytics.component.html",
    styleUrls: [ "./analytics.component.scss" ]
})
export class AnalyticsComponent implements OnInit {
    private analytics: AnalyticsConfig;
    private isProduction: boolean;

    public constructor(
        @Inject(AUTH_SERVICE) private authService: AuthService,
        config: AppConfig
    ) {
        this.isProduction = config.Production;
        this.analytics = config.Analytics;

    }

    public ngOnInit(): void {
        if (this.isProduction) {
            const head = document.getElementsByTagName("head")[0];
            const analyticsWindow = window as IAnalyticsWindow & typeof globalThis;

            this.setupClarity(head);
            this.setupGoogleAnalyticsAnonymously(head);

            if (this.authService.isHelpingImprovingTheProduct()) {
                analyticsWindow.clarity("consent");
            }
        }
    }

    private setupGoogleAnalyticsAnonymously(head: HTMLHeadElement) {
        const gaScript = document.createElement("script");
        gaScript.setAttribute("async", "true");
        gaScript.setAttribute("src", `https://www.googletagmanager.com/gtag/js?id=${this.analytics.GoogleTag}`);

        const gaScript2 = document.createElement("script");
        gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${this.analytics.GoogleTag}', { 'anonymize_ip': true });`;

        head.appendChild(gaScript);
        head.appendChild(gaScript2);
    }

    private setupClarity(head: HTMLHeadElement) {
        const clarityScript = document.createElement("script");
        clarityScript.setAttribute("type", "text/javascript");
        clarityScript.innerText = `(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", "${this.analytics.ClarityTag}")`;

        head.appendChild(clarityScript);
    }
}
