import { ErrorHandler, Inject, Injectable, InjectionToken } from "@angular/core";

export const ERROR_HANDLERS: InjectionToken<ErrorHandler[]> = new InjectionToken<ErrorHandler[]>("ERROR_HANDLERS");

@Injectable({ providedIn: "root" })
export class GlobalErrorHandler implements ErrorHandler {
    public constructor(
        @Inject(ERROR_HANDLERS) private handlers: ErrorHandler[]
    ) { }

    public handleError(error: any): void {
        for (const handler of this.handlers) {
            handler.handleError(error);
        }
    }
}
