import { AfterViewInit, Component, Inject, inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ApolloQueryResult } from "@apollo/client/core";
import { Apollo, gql, MutationResult, QueryRef } from "apollo-angular";
import { first, Subscription, take } from "rxjs";
import { IGenericTableHeader } from "../../../shared/generic-table/generic-table-header";
import { IGenericTableToolbarItem } from "../../../shared/generic-table/generic-table-toolbar-item";
import { GenericTableToolbarSearchItem } from "../../../shared/generic-table/generic-table-toolbar-item-search";
import { GenericTableComponent } from "../../../shared/generic-table/generic-table.component";
import { ProductsOverViewTable } from "./products-overview-table";
import { PimApiClient } from "../../../services/apis/api/api.service";
import { ProductsEditDialogData } from "../products-edit-dialog/products-edit-dialog-data";
import { DialogMode } from "../../../shared/enums/dialog-mode";
import { ProductsEditDialogComponent } from "../products-edit-dialog/products-edit-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ProductsEditDialogResult } from "../products-edit-dialog/products-edit-dialog-result";
import { DialogResult } from "../../../shared/enums/dialog-result";
import { AUTH_SERVICE, AuthService } from "../../../services/auth/auth.service";

@Component({
  selector: "app-products-overview",
  templateUrl: "./products-overview.component.html",
  styleUrl: "./products-overview.component.scss"
})
export class ProductsOverviewComponent implements OnInit, OnDestroy {

    protected tableToolbar: IGenericTableToolbarItem[] = [ new GenericTableToolbarSearchItem() ];

    protected tableHeaders: IGenericTableHeader<ProductsOverViewTable>[] = [
        {
            field: "ean",
            headerText: "Ean",
            type: "string",
        },
        {
            field: "sku",
            headerText: "Sku",
            type: "number",
        },
        {
            field: "orderInWarehouse",
            headerText: "Bestellen bij magazijn",
            type: "boolean",
        },
        {
            field: "favoriteSupplierName",
            headerText: "Voorkeursleverancier",
            type: "string",
        },
        {
            field: "createdAt",
            headerText: "Aangemaakt op",
            type: "datetime",
            format: "dd-MM-yyyy HH:mm",
        },
        {
            field: "changedAt",
            headerText: "Gewijzigd op",
            type: "datetime",
            format: "dd-MM-yyyy HH:mm",
        },
        {
            headerText: "Acties",
            commands: [
                {
                    title: "Openen",
                    icon: "e-search",
                    action: (entity: ProductsOverViewTable) => this.openDialog(entity),
                }
            ]
        }
    ];

    protected isLoading: boolean = true;

    protected products: ProductsOverViewTable[] = [];

    @ViewChild("productsTable")
    private table: GenericTableComponent<ProductsOverViewTable>;

    private suppliers: { id: number, name: string }[] = [];

    private queryRef: QueryRef<any>;
    private dataSubscription: Subscription;
    private suppliersDataSubscription: Subscription;

    constructor(
        private readonly pimApiClient: PimApiClient,
        private readonly dialog: MatDialog,
        private readonly snackBar: MatSnackBar,
    ) {
    }

    public ngOnInit(): void {
        this.queryRef = this.pimApiClient.GetGraphqlQuery().watchQuery<any>({
            query: gql`
            {
                products {
                    id,
                    ean,
                    sku,
                    orderInWarehouse,
                    createdAt,
                    changedAt
                    favoriteSupplier {
                        id,
                        name
                    }
                }
            }`,
            pollInterval: 60_000, // 1 minute
        });

        this.dataSubscription = this.queryRef.valueChanges.subscribe({
            next: (result: ApolloQueryResult<any>) => {
                if (result.errors != null) {
                    console.error(`Graphql error while fetching: ${result.errors}`);
                }

                this.isLoading = result.loading;
                this.products = (result.data.products as []).map((p: any) => ({
                    ean: p.ean,
                    sku: p.sku,
                    orderInWarehouse: p.orderInWarehouse,
                    favoriteSupplierId: p.favoriteSupplier?.id,
                    favoriteSupplierName: p.favoriteSupplier?.name ?? "[Geen]",
                    createdAt: new Date(p.createdAt),
                    changedAt: new Date(p.changedAt),
                } as ProductsOverViewTable));
            },
            error: (err: any) => {
                console.error(`Graphql error before fetching: ${err}`);
            }
        });

        this.suppliersDataSubscription = this.pimApiClient.GetGraphqlQuery().watchQuery<any>({
            query: gql`
            {
                suppliers {
                    id,
                    name
                }
            }`,
            pollInterval: 300_000, // 5 minute
        }).valueChanges.subscribe({
            next: (result: ApolloQueryResult<any>) => {
                if (result.errors != null) {
                    console.error(`Graphql error while fetching: ${result.errors}`);
                }

                this.suppliers = (result.data.suppliers as []).map((s: any) => ({
                    id: s.id,
                    name: s.name,
                }));
            },
            error: (err: any) => {
                console.error(`Graphql error before fetching: ${err}`);
            }
        });
    }

    public ngOnDestroy(): void {
        this.dataSubscription?.unsubscribe();
        this.suppliersDataSubscription?.unsubscribe();
    }

    protected openDialog(entity: ProductsOverViewTable) {
         const dialogData: ProductsEditDialogData = {
            mode: DialogMode.Edit,
            id: entity.id,
            orderInWarehouse: entity.orderInWarehouse,
            suppliers: this.suppliers,
            favoriteSupplierId: entity.favoriteSupplierId,
        };

        const dialog = this.dialog.open(ProductsEditDialogComponent, {
            minWidth: "300px",
            data: dialogData,
        });
        dialog.afterClosed().pipe(take(1)).subscribe((result: ProductsEditDialogResult) => {
            if (result != null && result.dialogResultMode === DialogResult.Updated) {
                const p = this.products.find((product) => product.id === result.id);

                if (p == null) {
                    console.error(`Product with id '${result.id}' not found.`);
                    this.snackBar.open("Er is iets misgegaan bij het opslaan.", "", {
                        duration: 3000
                    });
                    return;
                }

                this.pimApiClient.GetGraphqlQuery().mutate({
                    mutation: gql`
                    mutation updateProduct($input: UpdateProductInput!) {
                        updateProduct(input: $input) {
                            productResourceResult {
                                id
                            }
                        }
                    }`,
                    variables: {
                        input: {
                            id: p.id,
                            resource: {
                               sku: p.sku,
                                orderInWarehouse: result.orderInWarehouse,
                                favoriteSupplier: result.favoriteSupplierId
                            }
                        }
                    },
                })
                    .pipe(first())
                    .subscribe({
                        next: (result: MutationResult<unknown>) => {
                            this.queryRef.refetch();
                        },
                        error: (err: any) => {
                            console.error(`Graphql error before updating: ${err}`);
                            this.snackBar.open("Er is iets misgegaan bij het opslaan.", "", {
                                duration: 3000
                            });
                        }
                });
            }
        });
    }
}
