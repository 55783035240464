import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { mergeMap, Observable, of } from "rxjs";
import { TenantPublicResourceResult } from "./models/tenant-public-resource-result";

export const IDENTITY_API_BASE_URL: InjectionToken<string> = new InjectionToken("IDENTITY_API_BASE_URL");

@Injectable({
    providedIn: "root"
})
export class IdentityClient {
    constructor(
        @Inject(HttpClient) private readonly http: HttpClient,
        @Inject(IDENTITY_API_BASE_URL) private readonly baseUrl: string,
    ) { }

    public GetTenantPublicResourceResult(name: string): Observable<TenantPublicResourceResult | null> {
        return this.http.get<TenantPublicResourceResult>(`${this.baseUrl}/V1/Tenants/${name}`, {
            responseType: "json",
            observe: "response",
        }).pipe(mergeMap((response) => this.handleStatus(response)))
    }

    public GetTenantLogoUrl(tenantId: string): string {
        return `${this.baseUrl}/V1/Tenants/${tenantId}/logo`;
    }

    private handleStatus<T>(response: HttpResponse<T>): Observable<T | null> {
        if (response.status >= 200 && response.status < 300) {
            return of(response.body);
        }

        switch (response.status) {
            case 400:
                throw new Error("Bad Request");
            case 401:
                throw new Error("Unauthorized");
            case 403:
                throw new Error("Forbidden");
            case 404:
                throw new Error("Not Found");
            case 409:
                throw new Error("Conflict");
            case 500:
                throw new Error("Internal Server Error");
            default:
                throw new Error(`Unknown error: ${response.status.toString()}`);
        }
    }
}
