import { Injectable, InjectionToken } from "@angular/core";
import { GenericLegendItem } from "./GenericLegendItem";

@Injectable({
    providedIn: "root"
})
export class GenericLegendService {

    public get elements(): GenericLegendItem[] {
        return this._elements;
    }

    private _elements: GenericLegendItem[] = [];

    public resetElements(): void {
        this._elements = [];
    }

    public addHeaderElement(element: HTMLElement, order: number, isHeader: boolean = false): void {
        const elements = this._elements;
        elements.push({
            element,
            order,
            isHeader
        });
        this._elements = elements.sort(this.compareGenericItems);
    }

    private compareGenericItems(item1: GenericLegendItem, item2: GenericLegendItem): number {
        if (item1.order < item2.order) {
            return -1;
        }
        if (item1.order > item2.order) {
            return 1;
        }
        return 0;
    }
}

export const GENERIC_LEGEND_SERVICE: InjectionToken<GenericLegendService> = new InjectionToken("GENERIC_LEGEND_SERVICE");
