import { Injectable, InjectionToken, Injector } from "@angular/core";
import { Apollo } from "apollo-angular";
import { User, UserManager, UserManagerSettings } from "oidc-client-ts";
import { AsyncSubject, lastValueFrom } from "rxjs";
import { AppConfig } from "../config/models/app-config";

@Injectable({
    providedIn: "root"
})
export class AuthService {
    private manager: UserManager;
    private initializedSubject: AsyncSubject<void>;
    private user: User | null;
    private tenantName: string;
    private returnUrlKey: string;

    public constructor(
        readonly appConfig: AppConfig,
        private injector: Injector
    ) {
        // eslint-disable-next-line no-undef
        const rootUrl = location.origin;
        let tenantName: string = "";
        if (appConfig.Production) {
            // eslint-disable-next-line no-undef
            tenantName = location.host.split(".")[0] ?? "";
            if (!tenantName) {
                throw new Error("Could not retrieve tenantName")
            }
        } else {
            tenantName = "Tenant";
        }

        const authSettings: UserManagerSettings = {
            authority: appConfig.Identity.Auth.Authority,
            client_id: appConfig.Identity.Auth.ClientId,
            redirect_uri: `${rootUrl}${appConfig.Identity.Auth.RedirectUriSuffix}`,
            silent_redirect_uri: `${rootUrl}${appConfig.Identity.Auth.SilentRedirectUriSuffix}`,
            post_logout_redirect_uri: rootUrl,
            revokeTokensOnSignout: true,
            response_type: appConfig.Identity.Auth.ResponseType,
            scope: appConfig.Identity.Auth.Scopes,
            automaticSilentRenew: true,
            extraQueryParams: {
                "TenantName": tenantName
            }
        };
        // Logic to enable debug logging:
        // Log.setLogger(console);
        // Log.setLevel(Log.DEBUG);
        this.manager = new UserManager(authSettings);
        this.manager.events.addUserLoaded(() => {
            this.init();
        });
        this.manager.events.addAccessTokenExpired(() => this.logout());

        this.initializedSubject = new AsyncSubject();
        this.tenantName = tenantName;
        this.returnUrlKey = "returnUrl";
    }

    public async init(): Promise<boolean> {
        this.manager.clearStaleState();
        this.user = await this.manager.getUser();

        if (this.user == null) {
            this.initializedSubject.next();
            this.initializedSubject.complete();
            return false;
        }

        this.initializedSubject.next();
        this.initializedSubject.complete();
        return true;
    }

    public async isLoggedIn(): Promise<boolean> {
        // Wait until initialized
        await lastValueFrom(this.initializedSubject);

        return this.user != null && !this.user.expired;
    }

    public getUserId(): string | null {
        return this.user?.profile.sub ?? null;
    }

    public isHelpingImprovingTheProduct(): boolean {
        const is_helping_improving_products: boolean | undefined = this.user?.profile?.is_helping_improving_products as boolean;

        if (is_helping_improving_products === null) {
            console.error("is_helping_improving_products is null");
        }

        return is_helping_improving_products;
    }

    public hasScope(scope: string): boolean {
        return this.user?.scopes?.includes(scope) ?? false;
    }

    public getAuthorizationHeaderValue(): string | null {
        return this.user?.access_token ?
            `${this.user.token_type} ${this.user.access_token}` :
            null;
    }

    public getTenantName(): string {
        return this.tenantName;
    }

    public startAuthentication(): Promise<void> {
        // Save Return url
        window.sessionStorage.setItem(this.returnUrlKey, window.location.pathname + window.location.search);
        return this.manager.signinRedirect();
    }

    public async completeAuthentication(): Promise<void> {
        await this.manager.removeUser();

        try {
            this.user = await this.manager.signinRedirectCallback();
        } catch (error) {
            await this.manager.signinRedirect();
            return;
        }

        await this.manager.clearStaleState();

        // Restore return url
        const returnUrl: string | null = window.sessionStorage.getItem(this.returnUrlKey);

        if (returnUrl != null) {
            window.sessionStorage.removeItem(this.returnUrlKey)
            window.location.href = window.location.origin + returnUrl;
        } else {
            window.location.href = window.location.origin;
        }
    }

    public async completeSilentAuthentication(): Promise<void> {
        await this.manager.removeUser();
        await this.manager.signinSilentCallback();
    }

    public async logout(): Promise<void> {
        await this.manager.removeUser();
        this.user = null;

        this.injector.get(Apollo, null, {
            optional: true
        })?.client.resetStore();

        await this.manager.signoutRedirect();
    }
}

export const AUTH_SERVICE: InjectionToken<AuthService> = new InjectionToken("AUTH_SERVICE");
