import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ServiceModule } from "../../services/services.module";
import { SharedModule } from "../../shared/shared.module";
import { SuppliersOverviewComponent } from "./suppliers-overview/suppliers-overview.component";
import { SuppliersEditDialogComponent } from "./suppliers-edit-dialog/suppliers-edit-dialog.component";
import { CoreModule } from "../../core/core.module";

@NgModule({
  declarations: [
    SuppliersOverviewComponent,
    SuppliersEditDialogComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    ServiceModule,
    MatProgressSpinnerModule,
  ]
})
export class SuppliersModule { }
