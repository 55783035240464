import { Injectable } from "@angular/core";
import { AnalyticsConfig } from "./analytics-config";
import { ApiConfig } from "./api-config";
import { IdentityConfig } from "./identity-config";

@Injectable()
export class AppConfig {
    Production: boolean;
    showDialogOnError: boolean;
    Analytics: AnalyticsConfig;
    Identity: IdentityConfig;
    Api: ApiConfig;
}
