import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProductsEditDialogData } from "./products-edit-dialog-data";
import { ProductsEditDialogForm } from "./products-edit-dialog-form";
import { ProductsEditDialogResult } from "./products-edit-dialog-result";
import { DialogResult } from "../../../shared/enums/dialog-result";
import { DialogMode } from "../../../shared/enums/dialog-mode";
import { AUTH_SERVICE, AuthService } from "../../../services/auth/auth.service";

@Component({
  selector: "app-products-edit-dialog",
  templateUrl: "./products-edit-dialog.component.html",
  styleUrl: "./products-edit-dialog.component.scss"
})
export class ProductsEditDialogComponent {
    protected readonly form: FormGroup<ProductsEditDialogForm>;
    protected readonly isReadOnly: boolean = true;

    constructor(
        private readonly dialogRef: MatDialogRef<ProductsEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) protected data: ProductsEditDialogData,
        @Inject(AUTH_SERVICE) readonly authService: AuthService,
    ) {
        if (data.mode === DialogMode.Edit && authService.hasScope("pim:products:u")) {
            this.isReadOnly = false;
        }

        this.form = new FormGroup<ProductsEditDialogForm>({
            orderInWarehouse: new FormControl({ value: data.orderInWarehouse, disabled: this.isReadOnly }, { nonNullable: true }),
            favoriteSupplierId: new FormControl({ value: this.data.favoriteSupplierId, disabled: this.isReadOnly }, { nonNullable: true })
        });
     }

    protected submit(): void {
        this.form.markAllAsTouched();

        if (this.form.valid) {
            const result: ProductsEditDialogResult = {
                dialogResultMode: DialogResult.Updated,
                id: this.data.id,
                orderInWarehouse: this.form.controls.orderInWarehouse.value,
                favoriteSupplierId: this.form.controls.favoriteSupplierId.value
            };

            this.dialogRef.close(result);
        }
    }
}
