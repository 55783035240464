import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { HttpConfigInterceptor } from "./config/http-config.interceptor";
import { IdentityClient } from "./identity/identity.service";
import { PimApiClient } from "./api/api.service";


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HttpClientModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
        IdentityClient,
        PimApiClient,
    ]
})
export class ApiModule { }
