<div mat-dialog-title>
    Aanpassen
</div>
<div mat-dialog-content>
    <form [formGroup]="form" (ngSubmit)="submit()">
        <mat-grid-list cols="1" rowHeight="80px">
            <mat-grid-tile>
                <mat-form-field>
                    <mat-label>Naam</mat-label>
                    <input matInput [formControl]="form.controls.name" />
                    <mat-error *ngIf="form.controls.name.touched && form.controls.name.invalid"
                        color="warn">De naam is ongeldig.</mat-error>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field>
                    <mat-label>Inkoopkorting</mat-label>
                    <input matInput type="number" [formControl]="form.controls.purchaseDiscountInPercentage" />
                    <mat-error *ngIf="form.controls.purchaseDiscountInPercentage.touched && form.controls.purchaseDiscountInPercentage.invalid"
                        color="warn">De Inkoopkorting is ongeldig.</mat-error>
                </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>

        <div class="action-buttons" >
            <button *ngIf="isReadOnly === false" mat-raised-button type="submit" color="primary">Opslaan</button>
            <button mat-raised-button [mat-dialog-close]>Annuleren</button>
        </div>
    </form>
</div>
