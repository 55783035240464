import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CoreModule } from "../../core/core.module";
import { ServiceModule } from "../../services/services.module";
import { SharedModule } from "../../shared/shared.module";
import { SuppliersProductsEditDialogComponent } from "./suppliers-products-edit-dialog/suppliers-products-edit-dialog.component";
import { SuppliersProductsOverviewComponent } from "./suppliers-products-overview/suppliers-products-overview.component";
import { SuppliersProductsUploadDialogComponent } from "./suppliers-products-upload-dialog/suppliers-products-upload-dialog.component";

@NgModule({
  declarations: [
    SuppliersProductsOverviewComponent,
    SuppliersProductsUploadDialogComponent,
    SuppliersProductsEditDialogComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    ServiceModule,
    MatProgressSpinnerModule,
  ]
})
export class SuppliersProductsModule { }
