import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ApolloQueryResult } from "@apollo/client/core";
import { gql, MutationResult, QueryRef } from "apollo-angular";
import { first, Subscription, take } from "rxjs";
import { PimApiClient } from "../../../services/apis/api/api.service";
import { IGenericTableHeader } from "../../../shared/generic-table/generic-table-header";
import { IGenericTableToolbarItem } from "../../../shared/generic-table/generic-table-toolbar-item";
import { GenericTableToolbarSearchItem } from "../../../shared/generic-table/generic-table-toolbar-item-search";
import { GenericTableComponent } from "../../../shared/generic-table/generic-table.component";
import { SuppliersOverViewTable } from "./suppliers-overview-table";
import { SuppliersEditDialogData } from "../suppliers-edit-dialog/suppliers-edit-dialog-data";
import { DialogMode } from "../../../shared/enums/dialog-mode";
import { SuppliersEditDialogComponent } from "../suppliers-edit-dialog/suppliers-edit-dialog.component";
import { SuppliersEditDialogResult } from "../suppliers-edit-dialog/suppliers-edit-dialog-result";
import { DialogResult } from "../../../shared/enums/dialog-result";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-suppliers-overview",
  templateUrl: "./suppliers-overview.component.html",
  styleUrl: "./suppliers-overview.component.scss"
})
export class SuppliersOverviewComponent implements OnInit, OnDestroy {

    protected tableToolbar: IGenericTableToolbarItem[] = [ new GenericTableToolbarSearchItem() ];

    protected tableHeaders: IGenericTableHeader<SuppliersOverViewTable>[] = [
        {
            field: "name",
            headerText: "Naam",
            type: "string",
        },
        {
            field: "purchaseDiscountInPercentage",
            headerText: "Inkoopkorting",
            type: "number",
            format: "P",
        },
        {
            headerText: "Acties",
            commands: [
                {
                    title: "Openen",
                    icon: "e-search",
                    action: (entity: SuppliersOverViewTable) => this.openDialog(entity),
                }
            ]
        }
    ];

    protected isLoading: boolean = true;

    protected suppliers: SuppliersOverViewTable[] = [];

    @ViewChild("suppliersTable")
    private table: GenericTableComponent<SuppliersOverViewTable>;

    private queryRef: QueryRef<any>;
    private dataSubscription: Subscription;

    constructor(
        private readonly pimApiClient: PimApiClient,
        private readonly dialog: MatDialog,
        private readonly snackBar: MatSnackBar,
    ) {
    }

    public ngOnInit(): void {
        this.queryRef = this.pimApiClient.GetGraphqlQuery().watchQuery<any>({
            query: gql`
            {
                suppliers {
                    id
                    name
                    purchaseDiscountInPercentage
                }
            }`,
        });

        this.dataSubscription = this.queryRef.valueChanges.subscribe({
            next: (result: ApolloQueryResult<any>) => {
                if (result.errors != null) {
                    console.error(`Graphql error while fetching: ${result.errors}`);
                }

                this.isLoading = result.loading;
                this.suppliers = (result.data.suppliers as []).map((s: any) => ({
                    id: s.id,
                    name: s.name,
                    purchaseDiscountInPercentage: s.purchaseDiscountInPercentage,
                } as SuppliersOverViewTable));
            },
            error: (err: any) => {
                console.error(`Graphql error before fetching: ${err}`);
            }
        });
    }

    public ngOnDestroy(): void {
        this.dataSubscription?.unsubscribe();
    }

    protected openDialog(entity: SuppliersOverViewTable) {
         const dialogData: SuppliersEditDialogData = {
            mode: DialogMode.Edit,
            id: entity.id,
            name: entity.name,
            purchaseDiscountInPercentage: entity.purchaseDiscountInPercentage,
        };

        const dialog = this.dialog.open(SuppliersEditDialogComponent, {
            minWidth: "350px",
            data: dialogData,
        });
        dialog.afterClosed().pipe(take(1)).subscribe((result: SuppliersEditDialogResult) => {
            if (result != null && result.dialogResultMode === DialogResult.Updated) {
                const s = this.suppliers.find((supplier) => supplier.id === result.id);

                if (s == null) {
                    console.error(`Supplier with id '${result.id}' not found.`);
                    this.snackBar.open("Er is iets misgegaan bij het opslaan.", "", {
                        duration: 3000
                    });
                    return;
                }

                this.pimApiClient.GetGraphqlQuery().mutate({
                    mutation: gql`
                    mutation updateSupplier($input: UpdateSupplierInput!) {
                        updateSupplier(input: $input) {
                            supplierResourceResult {
                                id
                            }
                        }
                    }`,
                    variables: {
                        input: {
                            id: s.id,
                            resource: {
                                name: result.name,
                                purchaseDiscountInPercentage: result.purchaseDiscountInPercentage,
                            }
                        }
                    },
                })
                    .pipe(first())
                    .subscribe({
                        next: (result: MutationResult<unknown>) => {
                            this.queryRef.refetch();
                        },
                        error: (err: any) => {
                            console.error(`Graphql error before updating: ${err}`);
                            this.snackBar.open("Er is iets misgegaan bij het opslaan.", "", {
                                duration: 3000
                            });
                        }
                });
            }
        });
    }
}
